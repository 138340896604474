/**
 * @prettier
 */

;(function (Utils, $) {
  const self = Utils

  self.init = function () {
    self.collectData()

    $(window).resize(() => self.collectData())

    $(window).scroll(() => self.collectData())
  }

  self.collectData = function () {
    self.browserHeight = $(window).height()
    self.pageHeight = $(document).height()
    self.currentScroll = $(window).scrollTop()
  }

  self.hub = $('<div></div>')

  self.publish = function (name, data) {
    if (window.console) {
      console.log('Publish event: ' + name)
    }
    self.hub.trigger(name, data)
  }

  self.subscribe = function (name, callback) {
    self.hub.bind(name, function (event, data) {
      callback(data)

      return true
    })
  }

  self.isMobile = {
    Android() {
      return navigator.userAgent.match(/Android/i)
    },

    BlackBerry() {
      return navigator.userAgent.match(/BlackBerry/i)
    },

    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },

    Opera() {
      return navigator.userAgent.match(/Opera Mini/i)
    },

    Windows() {
      return navigator.userAgent.match(/IEMobile/i)
    },

    any() {
      return (
        this.Android() ||
        this.BlackBerry() ||
        this.iOS() ||
        this.Opera() ||
        this.Windows()
      )
    },
  }

  self.setCurrentSite = site =>
    talkableLocalStorage.setItem('currentSiteCachedSlug', site)

  self.getCurrentSite = () => talkableLocalStorage.getItem('currentSiteCachedSlug')

  self.trackGAEvent = function (category, action, label) {
    if (!category && !action) {
      throw new Error(
        'Please supply: Category and Action in order to register Google Analytics Event',
      )
    }

    window.ga?.('send', 'event', category, action, label)
  }

  self.trackGAClicks = () =>
    $(document).on('click', '.js-ga-click', e => {
      const category = $(e.currentTarget).data('category')
      const label = $(e.currentTarget).data('label') || $(e.currentTarget).attr('class')
      const action = $(e.currentTarget).data('action') || 'Click'
      const name = $(e.currentTarget).data('name')
      const link = $(e.currentTarget).attr('href')
      const target = $(e.currentTarget).attr('target')
      const router = $(e.currentTarget).data('router')

      if ($(e.currentTarget).attr('href') && !router) {
        e.preventDefault()
      }

      if (category) {
        // New vs. legacy method
        self.trackGAEvent(category, action, label)
      } else {
        window.ga?.('send', 'event', name, 'clicked')
      }

      if (!router && link) {
        if (e.ctrlKey || e.metaKey || target === '_blank') {
          window.open(link)
        } else {
          window.location = link
        }
      }
    })

  window.reloadAndClearHash = () => {
    window.location = `${window.location.origin}${window.location.pathname}${window.location.search}`
  }
})((window.Utils = window.Utils || {}), jQuery)
